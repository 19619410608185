import React,{useState} from 'react';
import { removeMultipleSpacesToOneSpace } from './Library';

/**
 * This function returns true if data is empty. 
 */
export function isEmpty(data){
    try{
        if(data=="" || data==undefined){
            return true;
        }
    }catch(error){
        return true;
    }
    
    return false;
}

/**
 * Returns form class adding required validation if there is.
 */
export function FormClassName(formInputError,formInputTouch,className,fieldName){
    var blank=className;
    if(formInputError==undefined)
        return blank;
    if(formInputTouch==undefined)
        return blank;
    if(formInputError[fieldName]==undefined)
        return blank;
    if(formInputTouch[fieldName]==undefined)
        return blank;
    if(formInputTouch[fieldName]==true && formInputError[fieldName])
        return blank+" as-required "
}

/**
 * This function retuns block with error message if any,
 */
export function ErrorMessage({formInputError,formInputTouch,name}){
    try{
        document.getElementById(name).classList.remove("as-required");
    }catch(error){}

    var blank=<></>;
    
    //if(Object.keys(formInputError).length>0 && Object.keys(formInputTouch).length>0)//If there is any error field than display blank
     //   var blank=<><span className="as-error-message" style={{display:"block"}}>&nbsp;</span></>;
    
    if(formInputError==undefined)
        return blank;
    if(formInputTouch==undefined)
        return blank;
    if(formInputError[name]==undefined)
        return blank;
    if(formInputTouch[name]==undefined)
        return blank;
    if(formInputTouch[name]==true && formInputError[name]){
        try{
            document.getElementById(name).classList.add("as-required");
        }catch(error){}
        return(
            <span className="as-error-message" style={{display:"block"}}>{formInputError[name]}</span>
        );
    }
        

    return(<></>);

        
}

/**
 * Get selected value for React Select.
 */
export function DDL_Value(items,value){
    if(items==undefined || items==null || items.length==0)
        return null;
    if(value==undefined || value==null || value === '')
        return null;
    try{
        
        if(!Array.isArray(value)){
            var selectedValue = items.filter(x=>x.value==value);
            if(selectedValue.length==0)
                return null;
            else 
                return selectedValue[0]
        }
        else{//For multi select
            
            var values=[];
            value.forEach(x=>{//Loop through selected value
                var z=items.filter(y=>y.value==x);
                if(z.length>0){
                    values.push(z[0]);
                }
            });
            return values;
        }
    }catch(error){
        return null;
    }
    
}

/**
 * This function returns active DDL for react select.
 */
export function DDL_Active(){
    return([
        {value:true,label:"YES"},
        {value:false,label:"NO"}
    ]);
}
export function DDL_Boolean(){
    return([
        {value:true,label:"YES"},
        {value:false,label:"NO"}
    ]);
}

export function DDL_ENGLISH_MONTH() {
    return ([
        { value: 1, label: "JANUARY" },
        { value: 2, label: "FEBRUARY" },
        { value: 3, label: "MARCH" },
        { value: 4, label: "APRIL" },
        { value: 5, label: "MAY" },
        { value: 6, label: "JUNE" },
        { value: 7, label: "JULY" },
        { value: 8, label: "AUGUST" },
        { value: 9, label: "SEPTEMBER" },
        { value: 10, label: "OCTOBER" },
        { value: 11, label: "NOVEMBER" },
        { value: 12, label: "DECEMBER" }
    ])
}


/**
 * This function validate the data based on schema.
 * @formInputData Form data to be validated.
 * @validationSchema Schema to valid data upon
 */
export function ValidateSchema(formInputData,validationSchema){
    var allErrors={};

    var errors={};//Contains all error message
    var touch={};//Contains touch=true for all fields

    var names = Object.keys(validationSchema);
    names.forEach(name => {
        touch[name]=true;
        var keys = Object.keys(validationSchema[name]);
        keys.every(key => {
            if(key=="required"){
                if(Array.isArray(_GetFormData(formInputData,name))){//Multi Select may return array with data or blank array
                    if(_GetFormData(formInputData,name).length==0){
                        errors[name]=validationSchema[name][key];//Getting message of required key.
                        return false;
                    }
                    return true;
                }else{//Normal Input
                    if(removeMultipleSpacesToOneSpace(_GetFormData(formInputData,name)+"").trim()===""){
                        errors[name]=validationSchema[name][key];//Getting message of required key.
                        return false;
                    }
                    return true;
                }
            }
            if(key=="number" && !_GetFormData(formInputData,name)==""){//If value is not blank and key=number
                if(isNaN(_GetFormData(formInputData,name))){
                    errors[name]=validationSchema[name][key];//Getting message of number key.
                    return false;
                }
                return true;
            }
            if(key=="equals"){//If equals key is present than compare with to.
                
                if((_GetFormData(formInputData,name)!=_GetFormData(formInputData,validationSchema[name][key].to))){
                    errors[name]=validationSchema[name][key].message;//Getting message of number key.
                    return false;
                }
                return true;
            }
            if(key=="date"  && !_GetFormData(formInputData,name)==""){
                
                if(isNaN(new Date(_GetFormData(formInputData,name)).getTime())){
                    errors[name]="Enter valid date.";//Getting message of number key.
                    return false;
                }else{//Valid Date
                    //Check for min and max
                    var minDate=new Date(validationSchema[name][key].min);
                    var maxDate=new Date(validationSchema[name][key].max);
                    var valueDate=new Date(_GetFormData(formInputData,name));

                    maxDate.setHours(0,0,0,0);
                    minDate.setHours(0,0,0,0);
                    valueDate.setHours(0,0,0,0);
                    if(IsValidDate(minDate) && IsValidDate(maxDate)){
                        //Check Both
                        
                        if(valueDate>=minDate && valueDate<=maxDate){}//Valid
                        else{
                            errors[name]=validationSchema[name][key].message;
                            return false;
                        }
                    }else if(IsValidDate(minDate)){
                        //Check only min date
                        if(valueDate>=minDate){}//Valid
                        else{
                            errors[name]=validationSchema[name][key].message;
                            return false;
                        }
                    }else if(IsValidDate(maxDate)){
                        //Check only max date
                        if(valueDate<=maxDate){}//Valid
                        else{
                            errors[name]=validationSchema[name][key].message;
                            return false;
                        }
                    }
                }
                return true;
            }
            if(key == "datetime"  && !_GetFormData(formInputData, name) == "") {
                
                if(isNaN(new Date(_GetFormData(formInputData,name)).getTime())) {
                    errors[name]="Enter valid date.";//Getting message of number key.
                    return false;
                }
                //  Valid Date
                else {
                    //  Check for min and max datetime
                    var minDate = new Date(validationSchema[name][key].min);
                    var maxDate = new Date(validationSchema[name][key].max);
                    var valueDate = new Date(_GetFormData(formInputData,name));

                    if(IsValidDate(minDate) && IsValidDate(maxDate)){
                        //  Check Both
                        if(valueDate >= minDate && valueDate <= maxDate) {} //  Valid
                        else {
                            errors[name] = validationSchema[name][key].message;
                            return false;
                        }
                    }
                    //  Check only min date
                    else if(IsValidDate(minDate)) {
                        if(valueDate >= minDate) {} //  Valid
                        else {
                            errors[name] = validationSchema[name][key].message;
                            return false;
                        }
                    }
                    //  Check only max date
                    else if(IsValidDate(maxDate)) {
                        if(valueDate <= maxDate) {} //  Valid
                        else {
                            errors[name] = validationSchema[name][key].message;
                            return false;
                        }
                    }
                }
                return true
            }
            if(key=="regexp"  && !_GetFormData(formInputData,name)==""){
                if(!validationSchema[name][key].regexp.test(_GetFormData(formInputData,name))){
                    errors[name]=validationSchema[name][key].message;//Getting message of number key.
                    return false;
                }
                return true;
            }
            if(key=="between"  && !_GetFormData(formInputData,name)==""){
                if ( 
                    parseFloat(_GetFormData(formInputData,name))<validationSchema[name][key].min
                    ||
                    parseFloat(_GetFormData(formInputData,name))>validationSchema[name][key].max
                    ){
                        errors[name]=validationSchema[name][key].message;//Getting message of number key.
                        return false;
                    }
                
                return true;
            }
            if(key=="length" && !_GetFormData(formInputData,name)==""){
                if(
                    (
                        (_GetFormData(formInputData,name)).trim().length<validationSchema[name][key].min
                        ||
                        (_GetFormData(formInputData,name)).trim().length>validationSchema[name][key].max
                    )
                ){
                    errors[name]=validationSchema[name][key].message;//Getting message of number key.
                    return false;
                }
                return true;
            }
            if(key=="decimal" && !_GetFormData(formInputData,name)==""){

                //For Precision & Scale
                if(validationSchema[name][key].p!=undefined || validationSchema[name][key].s!=undefined){
                    //Precision & Scale
                    var value=parseFloat(_GetFormData(formInputData,name)).toString().split(".");
                    var p=0;//precision
                    var s=0;//scale
                    if(value.length==1){//If one single number
                        p=value[0].length;
                    }else{//If number is splitted.
                        p=value[0].length+value[1].length;
                        s=value[1].length;
                    }
    
                    if(
                        (
                            (p>validationSchema[name][key].p)
                            ||
                            (s>validationSchema[name][key].s)
                        )
                    ){
                        errors[name]=validationSchema[name][key].message;//Getting message of number key.
                        return false;
                    }
                    return true;
                }
                //For Before & After.
                else if(validationSchema[name][key].b!=undefined || validationSchema[name][key].a!=undefined){
                    //Precision & Scale
                    var value=parseFloat(_GetFormData(formInputData,name)).toString().split(".");
                    var b=0;//Before Decimal
                    var a=0;//After Decimal
                    if(value.length==1){//If one single number
                        b=value[0].length;
                    }else{//If number is splitted.
                        b=value[0].length;
                        a=value[1].length;
                    }
    
                    if(
                        (
                            (b>validationSchema[name][key].b)
                            ||
                            (a>validationSchema[name][key].a)
                        )
                    ){
                        errors[name]=validationSchema[name][key].message;//Getting message of number key.
                        return false;
                    }
                    return true;
                }
                
            }
            
        });
   });

   allErrors={
       touch:touch,
       message:errors
   }
   
   return allErrors;
}


export function _GetFormData(formInputData,name){
    if(formInputData==undefined 
        || formInputData[name] == undefined
        || formInputData[name] == null
        || formInputData[name] === ""
        )
        return "";
    return formInputData[name];
}

//Returns true if the date is valid.
function IsValidDate(date){
    return (isNaN(new Date(date).getTime())?false:true);
}


/*
*******THIS IS USED TO DELETE VALIDATION DYNAMICALLY***********
    //Dynamic validation
    if(FC.GetFormData(formInputData,"exampleRadios","")=="option1")
        delete validationSchema.option2;
    if(FC.GetFormData(formInputData,"exampleRadios","")=="option2")
        delete validationSchema.option1;


        ******THIS IS FORM VALIDATION SCHEMA EXAMPLE******
        name: {
                required: "Name is required",
                number:"Input must be a number",
                
                regexp: {
                    regexp: "",
                    message: "Invalid reg exp"
                },
                between: {
                    min: 0.00,
                    max: 1000.00,
                    message: "Number must be between this and that"
                },
                length: {
                    min: 5,
                    max: 20,
                    message: "Length must be between 5 and 20"
                },
                decimal:{
                    p:5,
                    s:2,
                    message: "Maximum 5 digit before and after decimal and only 2 digit after decimal is allowed."
                },
                decimal:{
                    b:5,
                    a:2,
                    message: "Maximum 5 digit before and 2 digit after decimal is allowed."
                },
                date:{
                    min:'',
                    max:'',
                    message:"Date must fall between this and that."
                },
            }

            If date provided is invalid date then, error message: Enter valid date.
*/