import App from 'App';
import React, { useContext, useState } from 'react';


export const DayEndReportContext=React.createContext();

function DayEndReportContextProvider(props) {

    const[reportList,setReportList]=useState([]);

    const [reportParam,setReportParam]=useState(null);

    const [start,setStart]=useState(false);

    return (
        <DayEndReportContext.Provider value={{
            setReportList:setReportList,
            reportList:reportList,
            reportParam,
            setReportParam,
            start,
            setStart
        }}>
            {props.children}
        </DayEndReportContext.Provider>
    );
}

export default DayEndReportContextProvider;
