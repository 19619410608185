import React from 'react';
import {uuidv4} from 'library/Library';

/**
 * 
 * @buttonName is either button name or icon of dropdown button.
 * @buttonClass is class to be added in button. Sample: toogle-dropdown as-btn as-btn-round as-btn-round-small as-btn-borderless
 * @buttonTitle is the title to be displayed on hover.
 * @children <a href={void(0)}>One</a> or any thing that is shown in the dropdown
 * @dropdownContentPosition Position of dropdown menu to be shown, left of the button or right. Add drop-left / drop-right
 */
function Dropdown({buttonName,buttonClass,buttonTitle,children,dropdownContentPosition="",customStyle={},customChildStyle={}}) {
    var id="dropdown_"+uuidv4().split("-").join("_");
    return (
        <>
            <button className={buttonClass} style={customStyle} title={buttonTitle} onClick={()=>toggleDropdown(id)}>{buttonName}</button>
            <div className="as-dropdown" style={customChildStyle}>
                <div id={id} className={"as-dropdown-content "+dropdownContentPosition} style={{cursor:"pointer"}}>
                    {children}
                </div>
            </div>
        </>
    );
}


function toggleDropdown(id){
    

    var currentDropDown = document.getElementById(id);

    if(currentDropDown.classList.contains("as-show-dropdown")) {    //  If user clicks 
        currentDropDown.classList.remove("as-show-dropdown");
    }
    else{
        closeAllDropdown();
        currentDropDown.classList.toggle("as-show-dropdown");   //  Show only clicked dropdown.
    }
}


function closeAllDropdown() {
    var allDrp = document.getElementsByClassName("as-dropdown-content");
    for ( var i = 0; i < allDrp.length; i++) {
        var openDropdown = allDrp[i];
        if (openDropdown.classList.contains('as-show-dropdown')) {
            openDropdown.classList.remove('as-show-dropdown');
        }
    }
}

window.addEventListener('click', function(event) {     //  Close the dropdown if the user clicks outside of it.
    try{
        if (!event.target.matches(".toogle-dropdown")) {    //  Check if clicked element is a dropdown or not.
            if(
                event.target.parentElement.parentElement.classList.contains("toogle-dropdown")//If Path
                ||
                event.target.parentElement.classList.contains("toogle-dropdown")//If SVG
            ){//If it is svg inside of button.
                //Do nothing            
            }
            else{
                closeAllDropdown();
            }
        }
    }catch(error){

    }
    
});

export default Dropdown;