import React,{ useState,useEffect } from 'react';
import Loader from 'layout/loader/Loader';
import LoaderFromTop from 'layout/loader/LoaderFromTop';
import ProgressBarLoader from 'layout/loader/ProgressBarLoader';

export const LoaderContext = React.createContext()
/**
 * [setLoaderStatus,loading,isLoading]
 */
function useLoader(loaderData=[]) {
    /**
     * [
     *  {key:ddl,status:true}
     * ]
     */



    const [loader,setLoader]=useState(loaderData);
    const [activeElement,setActiveElement]=useState(null);//Stores which element is currently active.
    const [message, setMessage] = useState('')    //  Holds loading message.

    const setLoaderStatus=(key,status,activeElement=null,loadingMessage='Loading... Please Wait!')=>{
        setActiveElement(activeElement);
        setMessage(loadingMessage)
        setLoader(state=>{
            var oldState=[...state];
            var index=oldState.findIndex(x=>x.key==key);
            if(index==-1)//Not found
                oldState.push({key:key,status:status});
            else
                oldState[index]={key:key,status:status};
            return oldState;
        });
    }

    useEffect(()=>{

        if(activeElement!=null){
            if(!isLoading()){
                activeElement.focus();
            }
        }
    },[loader],[activeElement]);

    /**
     * It retunrs if it is in loading state for all keys. If key value is provided than it check for that specific key.
     * @param {*} key 
     */
    const isLoading=(key="")=>{
        if(key==""){
            if(loader.filter(x=>x.status==true).length>0)
                return true;
        }else{
            if(loader.filter(x=>x.status==true && x.key==key).length>0)//Checking specific key
                return true;
        }
         
        return false;
    }


    /**
     * Returns Loading Indicator
     * @param {Boolean} fromTop - Loader appears from top?
     * @param {Boolean} isProgressBar - Show progress bar with loading message.
     * @param {Number} progressValue - Progress value (0-100)
     * @param {Boolean} isContinuous - Shows spinner when true.
     */
    const loading = (fromTop = false, isProgressBar = false, progressValue = 0, isContinuous = false)=>{
        if(isLoading()){
            if(fromTop) return (<LoaderFromTop message={message} />)
            else {
                if(isProgressBar)
                    return (
                        <LoaderContext.Provider value={{progress: progressValue}}>
                            <ProgressBarLoader message={message} progress={progressValue} isContinuous={isContinuous} />
                        </LoaderContext.Provider>
                    )
                return(<Loader />)
            }
        }
        return (<></>);
    }

    return [setLoaderStatus,loading,isLoading];
}

export default useLoader;