import React,{useState, useRef,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck,faExclamation,faTimes,faInfo, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react'

/**
 * {type:'success',title:'Title',message:'Message',btnTypeId:1}
 * @btnTypeId = 1 for Confirm and 2 for ConfirmCancel button.
 * @type = (success,info,error,warning)
 */
export function AlertMessage(
    {
        animate = false,
        type,
        title, 
        message, 
        btnTypeId = 1, 
        btnConfirmText = "Yes", 
        btnCancelText = "Cancel", 
        btnExtraActionText,
        confirmCallBack=function(){},
        cancelCallBack=function(){},
        extraActionCallBack=function(){},
        animateCallBack=function(){}
    }
){
    var btnType='Confirm';
    if(btnTypeId==2)
        btnType='ConfirmCancel'
    else if(btnTypeId==3)
        btnType='ExtraAction'
    else if(btnTypeId==4)
        btnType='Cancel'
    else if(btnTypeId==5)
        btnType='Animate'
    
    //ReactDOM.unmountComponentAtNode(document.getElementById('app-alert'));
    const element = (
        <Alert 
            animate={animate}
            type={type}
            title={title}
            message={message}
            btnType={btnType}
            btnConfirmText={btnConfirmText}
            btnCancelText={btnCancelText}
            btnExtraActionText={btnExtraActionText}
            confirmCallBack={confirmCallBack}
            cancelCallBack={cancelCallBack}
            extraActionCallBack={extraActionCallBack}
            animateCallBack={animateCallBack}
            showBtn={false}
        />
      );
      var alert=document.createElement("div");
      document.getElementById('app-alert').appendChild(alert);
      ReactDOM.render(element, alert);
}

function Alert(
        {
            animate = false,
            type, 
            title, 
            message, 
            btnType = "Confirm", 
            btnConfirmText = "Okay", 
            btnCancelText = "Cancel", 
            btnExtraActionText,
            btnClass,
            btnName,
            confirmCallBack=function(){},
            cancelCallBack=function(){},
            extraActionCallBack=function(){},
            animateCallBack=function(){},
            showBtn=true
        }
        ) {
    const [show, setShow] = useState(!showBtn);

    const confirmBtnRef=useRef(null);
    const cancelBtnRef=useRef(null);
    const extraActionBtnRef=useRef(null);

    if(animate) {
        setTimeout(() => {
            setShow(false)
            animateCallBack()
        }, 5000);
    }

    useEffect(() => {
        if(show){
            setTimeout(()=>{
                if(cancelBtnRef.current!=null)
                    cancelBtnRef.current.focus();
                else if(confirmBtnRef.current!=null)
                    confirmBtnRef.current.focus();
                else if(extraActionBtnRef.current!=null)
                    extraActionBtnRef.current.focus();
            },300);
        }else{
            //alert();
        }
            
    });

    useEffect(() => {
        if(type == "licenserenewal") {
            setTimeout(() => {
                setShow(false)
            }, 5000);
        }
    }, [type])

    var alert_icon_class="";
    var alert_fa;
    var alert_title_class="";

    var alert_btn;
    var alert_trigger_button;

    if(type == "success"){
        alert_icon_class="as-alert-success-icon";
        alert_fa=faCheck;
        alert_title_class="as-alert-success-title";
    }
    else if(type == "warning"){
        alert_icon_class="as-alert-warning-icon";
        alert_fa=faExclamation;
        alert_title_class="as-alert-warning-title";
    }
    else if(type == "error"){
        alert_icon_class="as-alert-error-icon";
        alert_fa=faTimes;
        alert_title_class="as-alert-error-title";
    }
    else if(type == "info"){
        alert_icon_class="as-alert-info-icon";
        alert_fa=faInfo;
        alert_title_class="as-alert-info-title";
    }
    else if(type == "licenserenewal"){
        alert_icon_class="as-alert-error-icon";
        alert_fa=faCalendarPlus;
        alert_title_class="as-alert-error-title";
    }

    if (btnType == "Confirm") {
        alert_btn= <div className="as-alert-buttons"><button ref={confirmBtnRef} id="alert-btn-confirm" className="as-default-btn-solid" onClick={()=>{setShow(false);confirmCallBack();}}> {btnConfirmText}</button></div>;
    }
    else if (btnType == "ConfirmCancel") {
        alert_btn= <div className="as-alert-buttons"><button ref={cancelBtnRef}  id="alert-btn-cancel" className="as-default-btn-solid-gray" onClick={()=>{setShow(false);cancelCallBack();}}> {btnCancelText} </button><button ref={confirmBtnRef} id="alert-btn-confirm" className="as-default-btn-solid" onClick={()=>{setShow(false);confirmCallBack();}}>{btnConfirmText} </button></div>;
    }
    else if (btnType == "ExtraAction") {
        alert_btn= <div className="as-alert-buttons-extra-action"><button ref={cancelBtnRef}  id="alert-btn-cancel" className="as-default-btn-solid-gray" onClick={()=>{setShow(false);cancelCallBack();}}> {btnCancelText} </button><button ref={extraActionBtnRef} id="alert-btn-extra-action" className="as-default-btn-solid" onClick={()=>{setShow(false);extraActionCallBack();}}>{btnExtraActionText} </button><button ref={confirmBtnRef} id="alert-btn-confirm" className="as-default-btn-solid-green" onClick={()=>{setShow(false);confirmCallBack();}}>{btnConfirmText} </button></div>;
    }
    else if (btnType == "Cancel") {
        alert_btn= <div className="as-alert-buttons"><button ref={cancelBtnRef}  id="alert-btn-cancel" className="as-default-btn-solid-gray" onClick={()=>{setShow(false);}}> {btnCancelText} </button></div>;
    }
    else if (btnType == "Animate") {
        alert_btn= <div className="as-alert-buttons"><button ref={confirmBtnRef} id="alert-btn-confirm" className="as-default-btn-solid-yellow" onClick={()=>{setShow(false);confirmCallBack();}}> {btnConfirmText}</button></div>;
    }
    if(showBtn){
        alert_trigger_button=<button className={btnClass} onClick={()=>setShow(true)}>{btnName}</button>;
    }

    const focusOnButton = () => {
        if (cancelBtnRef.current) cancelBtnRef.current.focus()
        else if (confirmBtnRef.current) confirmBtnRef.current.focus()
        else if (extraActionBtnRef.current) extraActionBtnRef.current.focus()
    }

    if(show)
        return (
            <>
                {alert_trigger_button}
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false
                    }}
                >
                    <div className="as-alert as-alert-show" onClick={focusOnButton}>
                        <div className={`as-alert-content ${animate && 'animate-loader'}`}>
                            <div className={alert_icon_class}> <FontAwesomeIcon icon={alert_fa} color="white"/> </div><div className={alert_title_class}>
                                {title}
                            </div>
                            <div className="as-alert-message"> {message} </div>
                            <div className='as-alert-btn-wrapper'>
                                {alert_btn}
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            </>
        );
    else
        return(<>{alert_trigger_button}</>);
}

//export default Alert;