import React,{useState,useEffect,useRef, forwardRef, useImperativeHandle} from 'react';
import useFormV1 from 'hooks/useFormV1';
import { AddEvent_ESC } from 'library/Library';
import Select from 'react-select';
import { DDL_Active, DDL_Value, ErrorMessage } from 'library/Form';
import { FormStyle } from 'layout/select/Style';
import { ApiRequest, UrlParams } from 'library/APICall';
import useLoader from 'hooks/useLoader';
import { ShowSnackbar } from 'layout/snackbar/Snackbar';
import { AlertMessage } from 'layout/alert/Alert';



const PropertySwitch=React.forwardRef((props, ref)=>{

    const [setLoaderStatus, loading, isLoading] = useLoader();


    useEffect(()=>{
        var r1=AddEvent_ESC(props.pop);
        return ()=>{r1()}
    })

    const DefaultValue=()=>{
        return {
            property:null,
        };
    }
    const ValidateSchema=()=>{
        var schema={
            property:{
                required:"Please select property you want to switch to.",
                ddl:ddl.property
            }
        };
        return schema;
    }
    const {form,setForm,handle,fun,validate,resetForm}=useFormV1(DefaultValue,ValidateSchema);

    const[ddl,setDDL]=useState({property:[]})

    const ref_firstInput=useRef();

    useEffect(()=>{
        LoadDDL();
    },[])

    const LoadDDL=()=>{
        setLoaderStatus('ddl', true);
        ApiRequest({
            TYPE: 'GET', URL: '/app/setup/users/getpropertyswitching', CALLBACK: function (res) {
                
                if (res.error_code == 0) {
                    var data = res.data;
                    try{
                        if(JSON.parse(data.property).length==0) props.pop();
                        setDDL(p => ({
                            ...p,
                            property: JSON.parse(data.property)
                        }));
                        //ref_firstInput.current.focus();
                    }catch{
                        props.pop();
                    }

                }
            }, COMPLETE: function () {
                setLoaderStatus('ddl', false);
            }, DATA: {}
        });
    }

    const FocusProperty=()=>{
        setTimeout(()=>{
            try{

                ref_firstInput.current.focus();
            }catch{}
        },500)
    }

    useEffect(()=>{
        FocusProperty();
    },[isLoading()])

    useEffect(()=>{
        API_SwitchProperty(form.input.property);
    },[form.input.property]);

    const API_SwitchProperty =(property_id)=>{

        if(property_id==null || property_id==0 || property_id==undefined) return;

        setLoaderStatus('ddl', true);

        ApiRequest({
            TYPE: 'GET', URL: '/app/setup/users/switchproperty?id='+property_id, CALLBACK: function (res) {
                
                if (res.error_code == 0) {
                    try{
                        var otl=JSON.parse(res.data.otl).data.otl;
                        window.location="//"+ddl.property.filter(f=>f.value==property_id)[0].host+"?otl="+otl;
                    }catch{
                        var property_name=(ddl.property.filter(f=>f.value==property_id)[0].label);
                        AlertMessage({type:'error',title:'Switch Property',message:'Could not switch to '+property_name+'. Make sure your user id in '+property_name+' is not blocked.',btnTypeId:1,btnConfirmText:"Okay",confirmCallBack:()=>FocusProperty()})
                        setForm(f=>({
                            ...f,
                            input:{
                                property:null
                            }
                        }))
                    }
                }
            }, COMPLETE: function () {
                setLoaderStatus('ddl', false);
            }, DATA: {}
        });
    }


    return (
        <>
            {isLoading()?<>{
                <div className="col-12 col-m-12 col-s-12 col-x-12">
                    {loading(true)}
                </div>
                }</>:<>
                <div className="col-12 col-m-12 col-s-12 col-x-12">
                    <label htmlFor="property" className="as-required">
                        Property
                    </label>
                    <Select closeMenuOnSelect={true}
                        ref={ref_firstInput}
                        id="property"
                        name="property"
                        isClearable={false}
                        className="select-component uppercase"
                        styles={FormStyle()}
                        placeholder="Choose Option"
                        options={ddl.property}
                        onChange={(e) => {handle.onChange(e, "property")}}
                        onBlur={(e) => handle.onBlur(e, "property")}
                        value={fun.ddlValue(ddl.property, "property")}
                        menuPortalTarget={document.body}
                    />
                
                    {fun.errorMessage('property')}
                    
                </div>
            </>}
        </>
    );
});
export default PropertySwitch;