//Use to create base page for view & manage.
import React, { useState,useEffect,useContext } from 'react';
import { readQueryString, updatePageUrl, getJsonFromUrl } from 'library/Library';
import { ApiRequest, UrlParams } from 'library/APICall';
import { AppContext } from 'App';

/**
 * 
 * @menu_id Menu Id of page
 * @title Title of page.
 * @updateTabId It is the tab where updated is to be performed. If anyone moves to tab except this one, updateId is reset to 0.
 */
function usePage(menu_id,title,updateTabId=2) {

    var APP = useContext(AppContext);
    
    document.title=title.trim()+" Aegis"+APP.appState.product;

    const [menuId,setMenuId]=useState(menu_id);

    const [pageRoles,setPageRoles]=useState([]);


    //Calling to get the roles in this page.
    useEffect(()=>{
        if(menu_id=="") return;
        var xhr=ApiRequest({ TYPE:'GET', URL:"/Application/menuroles?menu_id="+menu_id, CALLBACK:function(response){
            setPageRoles(response.data.roles);
            //IF VIEW ROLE IS NOT ASSIGNED THEN SHOW NOT AUTHORIZED PAGE.
            if(response.data.roles.filter(x=>x.role_id==-1).length==0){
                window.location.href="/authorizationfailure";
                // APP.setAppState(p=>({
                //     ...p,
                //     is_unauthorized:true
                // }));
            }else{
                if(APP.appState.is_unauthorized==true){
                    APP.setAppState(p=>({
                        ...p,
                        is_unauthorized:false
                    }));
                }
            }
        }, COMPLETE:function(){}, DATA:{} })
        return()=>{xhr.abort()}
    },[menuId]);

    //Read parameter from t to get which tab to show.
    const [tabs, setTabs] = useState(readQueryString('t',1));

    const [updateId,setUpdateId]=useState(0);

    useEffect(()=>{
        //If view tab is clicked, update id is set back to 0.
        if(tabs!=updateTabId)//If the tab is not the one which is to perform update. Reset UpdateId to 0.
            setUpdateId(0);
        var url=window.location.href.split('?');
        var queryParameter=getJsonFromUrl();
        queryParameter.t=tabs;

        url=url[0]+"?"+UrlParams(queryParameter);

        updatePageUrl(url);
    },[tabs]);

    return [pageRoles,tabs,setTabs,updateId,setUpdateId];
    
}

export default usePage;