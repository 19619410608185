import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { AddEvent_ALT, AddEvent_ESC, uuidv4 } from 'library/Library';
import { AppContext } from 'App'
// import FocusTrap from 'focus-trap-react';

/**
 * Push components using push function. Key is required in every push
 *
 *  [modal,push,pop]
 */
function useModalStack() {

    //It holds pop up stack
    const [modal,setModal]=useState([]);

    //Used to add modal in stack
    const push=(m)=>{
        if(m.key==undefined || m.key==null) { alert("Add key in modal to use push method."); throw "Add key in modal to use push method.";}
        setModal(p=>{
            //If same key already exists do not push
            if(p.filter(x=>x.key==m.key).length==0)
                return [...p,m];
            return [...p];
        });
    }

    const pop=()=>{
        setModal(p=>{
            return p.filter(x=>x.key!=p[p.length-1].key);
        });
    }

    // clears all the stack
    const popAll = () => {
        setModal(pre => ([]))
    }

    useEffect(() => {
        if(modal && modal.length > 0) {
            //  Hide footer
            document.getElementById("as-page-footer").style.display = "none"
            //  Disable background scrolling
            document.body.style.overflow = 'hidden'
        }
        else {
            //  Show Page Footer
            document.getElementById("as-page-footer").style.display = "block"
            //  Enable background scrolling
            document.body.style.overflow = 'auto'
        }
    }, [modal])

    return [modal,push,pop,popAll];
}


/**
 * 
 * @size {Size of the modal: large, small and x-small.}
 * @header {Header Text}
 * @body {Body Content}
 * @footer {Footer Content (Action Buttons)}
 * @footerPostion {Alignment of footer (Default 'as-text-right', you can set 'as-text-left' if you want to align footer to the left.)}
 * @pop {pop function {() => pop()}}
 * @bodyOverfow by default it is auto, If select is hiding inside modal put 'visible' as parameter
 */
export function ModalPopUp ({size = '', header, body, footer, footerPosition = 'as-text-right', pop, bodyOverflow='auto', isForTouch = false, enableOutsideClick = true}) {

    const { isSubWindow } = useContext(AppContext)

    //  Create dynamic modal ref (This is used if there is more than one modal opened in a page)
    const modalRef = {}
    const modalID = `m_id-${uuidv4()}`
    modalRef['modal_' + modalID] = useRef(null)


    //  Disable focus trap if clicked outside of the modal container
    useOutsideClickHandler(modalRef['modal_' + modalID], () => setIsTrapActive(!enableOutsideClick))


    //  To enable or disable focus trap
    const [isTrapActive, setIsTrapActive] = useState(true)


    if (pop == undefined){
        alert('Please add pop method to ModalPopUp.')
        return
    }

    var modalSize = ''
    
    if (size == 'large') modalSize = 'as-large-modal-popup'
    else if (size == 'small') modalSize = 'as-small-modal-popup'
    else if (size == 'x-small') modalSize = 'as-x-small-modal-popup'
    else if (size == 'full-scale') modalSize = 'as-full-scale-modal-popup'


    const activateTrap = () => {
        setIsTrapActive(true)
    }


    return (
        // <FocusTrap
        //     active={isTrapActive}
        //     focusTrapOptions={{
        //         fallbackFocus: `#${modalID}`,
        //         escapeDeactivates: false,
        //         allowOutsideClick: enableOutsideClick
        //     }}
        // >
            <div
                id={modalID}
                ref={modalRef['modal_' + modalID]}
                className={`as-modal-popup-wrapper${isSubWindow ? ' sub-window' : ''}`}
                tabIndex={-1}
                onClick={activateTrap}
            >
                <div className={`as-modal-popup ${modalSize}`}>
                    <button className={`as-close-modal-popup ${size} ${isForTouch && 'full-scale'}`} onClick={pop}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    {header && <div className='as-modal-popup-header'>{header}</div>}
                    <div className="as-modal-popup-body" style={{overflow:bodyOverflow}}>{body}</div>
                    {footer && <div className={`as-modal-popup-footer ${footerPosition}`}>{footer}</div>}
                </div>
            </div>
        // </FocusTrap> 
    )
}


/**
 * 
 * @header Contains Header Title
 * @body Contains Body
 * @pop Pop Method
 */
export function SliderPopUp ({header = null, body, pop, width=400, side='', enableOutsideClick = true}) {

    const { isSubWindow } = useContext(AppContext)

    //  Create dynamic modal ref (This is used if there is more than one modal opened in a page)
    const sliderModalRef = {}
    const sliderModalID = `m_id-${uuidv4()}`
    sliderModalRef['slider_modal_' + sliderModalID] = useRef(null)


    //  Disable focus trap if clicked outside of the modal container
    useOutsideClickHandler(sliderModalRef['slider_modal_' + sliderModalID], () => setIsTrapActive(!enableOutsideClick))


    //  To enable or disable focus trap
    const [isTrapActive, setIsTrapActive] = useState(true)
    
    if(pop==undefined){
        alert("Please add pop method to SliderPopUp.")
        return;
    }

    const activateTrap = () => {
        setIsTrapActive(true)
    }

    return (
        // <FocusTrap
        //     active={isTrapActive}
        //     focusTrapOptions={{
        //         fallbackFocus: `#${sliderModalID}`,
        //         escapeDeactivates: false,
        //         allowOutsideClick: enableOutsideClick
        //     }}
        // >
            <div
                id={sliderModalID}
                ref={sliderModalRef['slider_modal_' + sliderModalID]}
                className={`as-slider-popup-backdrop ${side}${isSubWindow ? ' sub-window' : ''}`}
                tabIndex={-1}
                onClick={activateTrap}
            >
                <div className='as-slider-popup-container' style={{ maxWidth: width + "px", minWidth: width + "px", }}>
                    {
                        header != undefined && header != null
                        ?
                            <div className='as-slider-popup-header'>
                                {header}
                            </div>
                        :
                            <></>
                    }
                    <div className="as-slider-popup-body">
                        {body}
                    </div>
                </div>
            </div>
        // </FocusTrap>
    )
}


/**
 * Checks if clicked outside or not.
 * @param {Object} ref Ref name of the element
 * @param {Function} callback Callback function
 */
const useOutsideClickHandler = (ref, callback = () => {}) => {
    useEffect(() => {
        const handleOutsideClick = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        }

        //  Bind the event listener
        document.addEventListener('mousedown', handleOutsideClick)

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [ref])
}

export default useModalStack;