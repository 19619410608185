import React,{useState,useEffect,useRef, forwardRef, useImperativeHandle} from 'react';
import { ValidateSchema, ErrorMessage, FormClassName } from 'library/Form';
import { HideModal } from 'layout/modal/Modal';
import { ApiRequest } from 'library/APICall';
import Loader from 'layout/loader/Loader';
import { ResponseSnackbar, ShowSnackbar } from 'layout/snackbar/Snackbar';
import { AlertMessage } from 'layout/alert/Alert';
import { AddEvent_ESC } from 'library/Library';

const ChangePassword=React.forwardRef((props, ref)=>{

    useEffect(()=>{
        var r1=AddEvent_ESC(props.pop);
        return ()=>{r1()}
    })
    
    useImperativeHandle(ref, () => ({
        UpdatePassword(){
            ProceedSave();
        }
    }));
    const oldPasswordRef=useRef(null);
    const newPasswordRef=useRef(null);
    const confirmPasswordRef=useRef(null);

    const [formInputData,setInputdata]=useState({
        old:'',
        new:'',
        confirm:''
    });
    const [formInputError,setFormInputError]=useState({});//Stores error message.
    const [formInputTouch,setFormInputTouch]=useState({});//Stores field that are touched once.

    const[loader,showLoader]=useState(false);


    useEffect(()=>{
        setTimeout(()=>{
            oldPasswordRef.current.focus();
        },500)
        
    },[]);

    useEffect(()=>{//On change of formInputData, call validateInput
        validateInput(formInputData,false);
    },[formInputData]);

    const validateInput=(data,forceCheck)=>{
        var errors={};
        var validationSchema={
            old:{
                required:"Old password is required",
                length:{
                    min:5,
                    max:50,
                    message:"Password must be 8 to 50 character long."
                }
            },
            new:{
                required:"New Password is required",
                length:{
                    min:5,
                    max:50,
                    message:"Password must be 8 to 50 character long."
                }
            },
            confirm:{
                required:"Confirm password is required",
                equals:{
                    to:"new",
                    message:"Confirm password must be same as new password."
                }
            }
        };
        
        errors=ValidateSchema(data,validationSchema,forceCheck);
        if(forceCheck){//If forceCheck is set, update setFormInputTouch. It will update touch of all fields.
            setFormInputTouch(errors.touch);
        }
        setFormInputError(errors.message);
       
        if(Object.keys(errors.message).length==0) return true;//Valid
        else return false;
    }
    
    

    const ProceedSave=()=>{
        var inputdata={
            old_password:formInputData.old,
            password:formInputData.new
        }
        if(validateInput(formInputData,true)){
            if(inputdata.old_password==inputdata.password){
                AlertMessage({type:'warning',title:'Password',message:'New password and old password must be different.',btnTypeId:1,btnConfirmText:"Ok",confirmCallBack:()=>{
                    oldPasswordRef.current.focus();
                }});
                
                return;
            }
            showLoader(true);
            ApiRequest({ TYPE:'POST', URL:'/app/setup/Users/changepassword', CALLBACK:function(res){
                ResponseSnackbar(res,'',10000);
                if(res.error_code==1){
                    return;
                }
                props.forcepop();
                window.location.reload();
            }, COMPLETE:function(){
                showLoader(false);
                if(oldPasswordRef.current!=null)
                    oldPasswordRef.current.focus();
            }, DATA:inputdata });
        }
    }
    if(loader)
        return(  <div ><Loader /></div>)

    return (
        <>
                <form onSubmit={(e)=>{e.preventDefault();ProceedSave()}}>
                                <div className="col-12 col-m-12 col-s-12 col-x-12">
                                    <span><span class="as-text-red">*NOTE: </span> New password must be 8-50 character long. It must contain one capital letter, one small letter and a number.</span>
                                </div>
                    
                                <div className="col-12 col-m-12 col-s-12 col-x-12">
                                    <label htmlFor="old" className="as-required"><small>Old Password</small></label>

                                    <input id="old" 
                                        name="old" 
                                        type="password" 
                                        className={FormClassName(formInputError,formInputTouch,"as-form-component as-input-small ","old")}
                                        placeholder="Old Password" 
                                        autoComplete="off"
                                        ref={oldPasswordRef}
                                        onChange={(e)=>{
                                            setInputdata({...formInputData,old:e.target.value});
                                        }}
                                        value={formInputData.old}
                                    />
                                    <ErrorMessage 
                                        formInputError={formInputError}
                                        formInputTouch={formInputTouch}
                                        name={"old"}
                                    />

                                </div>
                                <div className="col-12 col-m-12 col-s-12 col-x-12">
                                    <label htmlFor="new" className="as-required"><small>New Password</small></label>

                                    <input id="new" 
                                        name="new" 
                                        type="password" 
                                        className={FormClassName(formInputError,formInputTouch,"as-form-component as-input-small ","new")}
                                        placeholder="New Password" 
                                        autoComplete="off"
                                        ref={newPasswordRef}
                                        onChange={(e)=>{
                                            setInputdata({...formInputData,new:e.target.value});
                                        }}
                                        value={formInputData.new}
                                    />

                                    <ErrorMessage 
                                        formInputError={formInputError}
                                        formInputTouch={formInputTouch}
                                        name={"new"}
                                    />

                                </div>
                            
                                <div className="col-12 col-m-12 col-s-12 col-x-12">
                                    <label htmlFor="confirm" className="as-required"><small>Confirm Password</small></label>

                                    <input id="confirm" 
                                        name="confirm" 
                                        type="password" 
                                        className={FormClassName(formInputError,formInputTouch,"as-form-component as-input-small ","confirm")}
                                        placeholder="Confirm Password" 
                                        autoComplete="off"
                                        ref={confirmPasswordRef}
                                        onChange={(e)=>{
                                            setInputdata({...formInputData,confirm:e.target.value});
                                        }}
                                        value={formInputData.confirm}
                                    />
                                    <ErrorMessage 
                                        formInputError={formInputError}
                                        formInputTouch={formInputTouch}
                                        name={"confirm"}
                                    />

                                </div>
                                
                    </form>
        </>
    );
});
export default ChangePassword;