import {ShowSnackbar, ResponseSnackbar} from 'layout/snackbar/Snackbar';
import {ShowLoginForm} from 'modules/login/Login.js';
import { AppConfig, getCookie, setCookie } from './Library';

/**
 * Concat this function to URL with ?. It will append parameter in the url.
 * @obj json object as get parameter.
 */
export const UrlParams = (obj) =>{
    return Object
        .keys(obj)
        .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
        .join('&');
}

/**
 * {
 *     TYPE:'GET',
 *     URL:'',
 *     CALLBACK:function(res){},
 *     COMPLETE:function(){},
 *     DATA:{}
 * }
 * @TYPE  GET/POST
 * @URL URL to send request on.
 * @CALLBACK Callback function as response. It get response of true if it is failed.
 * @DATA Request data in json format. It also accepts FormData.
 */
export function ApiRequest({TYPE='GET',URL='',CALLBACK=function(res){},COMPLETE=function(){},DATA={}}){
    
    var DATA = recFilterRequest(DATA);
    
    // 1. Create a new XMLHttpRequest object
    let xhr = new XMLHttpRequest();
    
    // 2. Configure it: GET-request for the URL /article/.../load
    //process.env.REACT_APP_API_URL
    var final_url=AppConfig("api_url")+(URL[0]=='/'?URL:'/'+URL);
    var url_params= extractParamsFromUrl(final_url);
    //var filtered_url_params= recFilterRequest(url_params);
    var parsedUrl = new PARSEURL(final_url);
    var filtered_url=parsedUrl.origin + parsedUrl.pathname;
    xhr.open(TYPE, filtered_url+"?"+url_params);

    xhr.responseType = 'json'

    xhr.setRequestHeader('Authorization', 'Bearer '+localStorage.token);

    // 3. Send the request over the network
    if(DATA instanceof FormData)//If form data
        xhr.send((DATA));
    else{//If json data
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(DATA));
    }
        

    // 4. This will be called after the response is received
    xhr.onload = function() {
        
        //if (xhr.status != 200) { // analyze HTTP status of the response
            //alert(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
        //} else { // show the result
            //alert(`Done, got ${xhr.response.length} bytes`); // response is the server
            try{
                if(processResponse(xhr.response))
                    CALLBACK(xhr.response,xhr.responseURL);
            }catch(error){
                //alert("Error");
                if(error==null || error.message=='response is null')
                    ShowSnackbar('info',"Please wait, you will be connected shortly...",5000);
                else
                    alert("Unexpected Error: "+error)
                console.log(error);
            }
        
            COMPLETE();
    };

    // xhr.onprogress = function(event) {
    //     if (event.lengthComputable) {
    //         alert(`Received ${event.loaded} of ${event.total} bytes`);
    //     } else {
    //         alert(`Received ${event.loaded} bytes`); // no Content-Length
    //     }
    // };

    xhr.onerror = function() {
        //alert("Request failed");
        COMPLETE();
    };
    //Call xhr.abort(); to abort request.
    return xhr;
}


function processResponse(response){
    if(getCookie("_ASuL_")==""){
        
        if(document.getElementById("app-login").innerHTML=='')
            ShowLoginForm();
        return false;
    }
    if(response==null){
        ShowSnackbar('info',"Please wait, you will be connected shortly...",5000);
        return false;
    }
    if(response.hasOwnProperty('error_code')){//Valid response.
        try{
            
            if(response.status_code=="200"){
                if(response.data.session_status.continue_request){
                    if(response.data.session_status.token!=null)
                        localStorage.token=response.data.session_status.token;
                }else{
                    localStorage.token="";
                }
                return true;
            }
        }catch(error){
            return true;//if session status extraction is failed.
        }
        
        if(response.status_code=="400"){//Model Validation failure. Currently it only shows one message.
            try{
                ShowSnackbar('error',response.data.model_validation[0].error_messages[0]);
            }catch(error){

            }
        }


        if(response.status_code=="401"){
            if(document.getElementById("app-login").innerHTML=='')
                ShowLoginForm();
            //ShowSnackbar('error','You are logged out. Please login to continue.');
            return false;
        }
    }
    return true;
}

export function DownloadFile(param){
    ShowSnackbar('info','Downloading file. Please wait!');
    var URL="/app/files/download";
    URL=AppConfig("api_url")+(URL[0]=='/'?URL:'/'+URL)+"?"+UrlParams({q:param.q});
        
    // 1. Create a new XMLHttpRequest object
    let xhr = new XMLHttpRequest();
    
    // 2. Configure it: GET-request for the URL /article/.../load
    //process.env.REACT_APP_API_URL
    xhr.open("GET", URL);//GET,'/example/url'

    xhr.responseType = 'blob'

    xhr.setRequestHeader('Authorization', 'Bearer '+localStorage.token);
    
    xhr.send();

    // 4. This will be called after the response is received
    xhr.onload = function() {
        var blob = xhr.response;
        console.log(blob)
        saveBlob(blob, ""+new Date().getTime()+param.file_extension);
    };

    xhr.onerror = function() {
        alert("Request failed");
    };
    //Call xhr.abort(); to abort request.
}

function saveBlob(blob, fileName) {

    if(blob.type=="application/json"){
        var reader = new FileReader();
        reader.onload = function() {
            var res=JSON.parse(reader.result);
            if (processResponse(res)){
                ResponseSnackbar(res);
            }
        }
        reader.readAsText(blob);
        return;
    }

    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    
    a.dispatchEvent(new MouseEvent('click'));
}

export function CreateImageBlob(url){

    return new Promise(resolve => {
       
    var URL=url;
   
        
    // 1. Create a new XMLHttpRequest object
    let xhr = new XMLHttpRequest();
    
    // 2. Configure it: GET-request for the URL /article/.../load
    //process.env.REACT_APP_API_URL
    xhr.open("GET", URL);//GET,'/example/url'

    xhr.responseType = 'blob'

    xhr.setRequestHeader('Authorization', 'Bearer '+localStorage.token);
    
    xhr.send();
    var returnValue = null;
    // 4. This will be called after the response is received
    xhr.onload = function () { 
        var supproted_image_types = ["image/png", "image/jpg","image/jpeg"]             
        var blob = xhr.response;
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        if(supproted_image_types.some(x=> x == blob.type)){ // only make blob of images ignore other file types
            returnValue = imageUrl;
            resolve(returnValue); 
        }else {
            resolve(null)
        }     
        // saveBlob(blob, ""+new Date().getTime()+param.file_extension);       
    };

    xhr.onerror = function() {
        alert("Request failed");
        resolve(returnValue); 
    };
     
    //return returnValue;        
             
      });

    
}

export function PreviewFile(param){
    ShowSnackbar('info','Opening File. Please wait!');
    var URL="/app/files/download";
    URL=AppConfig("api_url")+(URL[0]=='/'?URL:'/'+URL)+"?"+UrlParams({q:param.q});
        
    // 1. Create a new XMLHttpRequest object
    let xhr = new XMLHttpRequest();
    
    // 2. Configure it: GET-request for the URL /article/.../load
    //process.env.REACT_APP_API_URL
    xhr.open("GET", URL);//GET,'/example/url'

    xhr.responseType = 'blob'

    xhr.setRequestHeader('Authorization', 'Bearer '+localStorage.token);
    
    xhr.send();

    // 4. This will be called after the response is received
    xhr.onload = function() {
        var blob = xhr.response;
        console.log(blob)
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        var new_window = window.open(imageUrl, '_blank', `width=600,height=900,left=${window.innerWidth * 30/100}`);
        new_window.focus();
        new_window.document.title = "File Preview"
        
    };

    xhr.onerror = function() {
        alert("Request failed");
    };
    //Call xhr.abort(); to abort request.
}

function formatDateAsString(date) {//TO: "2020-01-01T01:01:01"
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

function recFilterRequest(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            var value = obj[key];
            if(value instanceof Date || isDateStringWithTimezone(value)){//                
                var filtered_datetime = formatDateAsString(new Date(value));
				obj[key] = filtered_datetime;
			}
			
            //console.log(`${key}: ${typeof value}`);
            //console.log(`${key}: ${value instanceof Date == true ? "date" : "n/a"}`);
            if (typeof value === 'object' && value !== null) {
                recFilterRequest(value); // Recursively call recFilterRequest for nested objects
            }
        }
    }
	return obj;
}

function FormatIfDate(value){
    if(value instanceof Date || isDateStringWithTimezone(value)){//                
        var filtered_datetime = formatDateAsString(new Date(value));
        return filtered_datetime;
    }
    return value;
}

function extractParamsFromUrl(url) {
    const searchParams = new URL(url).searchParams;
    const params = {};
    
    var array_keys=[];

    for (var [key, value] of searchParams.entries()) {
        value = FormatIfDate(value);
        if(params.hasOwnProperty(key)){//URL has array of requestes
            if(!array_keys.includes(key)) array_keys.push(key)
            if(Array.isArray(params[key])){//It it is already converted into array
                params[key].push(value)
            }else{//If it is not yet converted into array
                params[key] = [params[key],value]//Make array with 2 value, old & new
            }
        }else{
            params[key] = value;
        }
        
    }
    
    //If array request then convert it back into get array format, else UrlParams();
    //var array_request=GetJsonWithSomeKeys(params,array_keys);
    //var non_array_request=GetJsonWithoutSomeKeys(params,array_keys);

    return convertJsonToUrlParam(params);
}

function convertJsonToUrlParam(obj) {
    //param: {a: [1, 2, 3], b: [4, 5],c:1,d:2};
    //response: "a=1&a=2&a=3&b=4&b=5&c=1&d=2"

    let queryString = '';
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (Array.isArray(value)) {
                value.forEach(v => {
                    queryString += `${encodeURIComponent(key)}=${encodeURIComponent(v)}&`;
                });
            } else {
                queryString += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
            }
        }
    }
    // Remove the trailing '&' character
    queryString = queryString.slice(0, -1);
    return queryString;
}

function GetJsonWithoutSomeKeys(val,exclude_keys){
    var newX={};
    for (var key in val) {
        if (!exclude_keys.includes(key)) {
            newX[key] = val[key];
        }
    }
    return newX;
}

function GetJsonWithSomeKeys(val,include_keys){
    var newX={};
    for (var key in val) {
        if (include_keys.includes(key)) {
            newX[key] = val[key];
        }
    }
    return newX;
}

function PARSEURL(value){
    return new URL(value);
}
function isDateStringWithTimezone(str) {
    // Regular expression to match the date format with timezone
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z$/;
    return regex.test(str);
}
