import React, { useState } from 'react';
import './Modal.css';
import { uuidv4 } from 'library/Library';
import ReactDOM from 'react-dom';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';



/**
 * {modalTypeId:"1",title:"Title",content:(<></>)}
 * @modalTypeId : 1 = Large, 2 = Medium , 3 = Small, 4= X-Small. (1 Default)
 * @title : Title of modal
 * @content : body & footer of modal. It must be wrapped into 2 div with class as-modal-body & as-modal-footer
 * @fullscreen : Can be made full screen?
 */
export function ShowModal({modalTypeId="",title="Title",content=(<></>),fullScreen=false}){

    var modalType="";
    if(modalTypeId==2) modalType="as-modal-medium";
    else if(modalTypeId==3) modalType="as-modal-small";
    else if(modalTypeId==4) modalType="as-modal-x-small";

    ReactDOM.unmountComponentAtNode(document.getElementById('app-modal'));
    const element = (
        <Modal 
            modalType={modalType}
            title={title}
            content={content}
            show={true}
            fullScreen={fullScreen}
        />
      );
      ReactDOM.render(element, document.getElementById('app-modal'));
}

export function HideModal(){
    ReactDOM.unmountComponentAtNode(document.getElementById('app-modal'));
    const element = (
        <Modal 
            show={false}
        />
      );
    ReactDOM.render(element, document.getElementById('app-modal'));
}
/**
 * 
 * @modelType : as-modal-medium / as-modal-small / as-modal-x-small
 * @title : Title of modal
 * @content : body & footer of modal
 * @footer : If left blank gives a close button
 */
function Modal({modalType="",title="",content,show=true,fullScreen=false}) {

    const[hide,setHide]=useState(!show);

    var id="Modal_"+uuidv4();
    if(hide)
        return (<></>);
    return (
        <>
        <div id={id} className={"as-modal "} style={{display:"block"}}>
            <div className={"as-modal-content "+modalType}>
                <div className="as-modal-header" style={{height:"50px"}}>
                    <span className="as-modal-title">{title}</span>
                    {(fullScreen)?
                        <span className="as-modal-title" style={{float:"right",cursor:"pointer"}}
                            onClick={()=>{
                                document.getElementsByClassName('as-modal-content')[0].classList.toggle('as-modal-full-screen');
                            }}
                        ><FontAwesomeIcon icon={faExpand}/></span>
                        : <></>
                    }
                </div>
                {content}
            </div>
        </div>
        </>
    );
}