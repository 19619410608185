import React from "react";
import styles from  'InvalidTZ.css'
function InvalidTZ(){

    var copyCode = () => {
        var codeElement = document.getElementById('code-TZ');
        var range = document.createRange();
        range.selectNode(codeElement);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
      }

    return (
        <div id="body-TZ">
            <div id="code-container-TZ">
                <div id="code-header-TZ">
                <h3>To use Aegis Software, run this in your command & refresh this page.</h3>
                <button id="copy-button-TZ" onClick={copyCode}>Copy</button>
                </div>
                <pre id="code-TZ">
                    tzutil /s "Nepal Standard Time"
                </pre>
            </div>
        </div>
    );

    

}

export default InvalidTZ;