import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import usePage from 'hooks/usePage';

function Unauthorized() {
    usePage("","Authorization Failure | ");

    return (
        <div className='as-page-not-authorized'>
            <svg className="as-local-svg as-lock-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 69">
                <path className='as-lock-path' d="M31.23,4.14l.14.06L30.81,4a1.31,1.31,0,0,1,.28.09Z"/>
                <path className='as-lock-path' d="M42.54,20.5V34h0V20.5A17.47,17.47,0,0,0,37.38,8.13a17.68,17.68,0,0,0-3.75-2.85.8.8,0,0,0,.16.08l.16.09c.28.15.56.33.83.51a8.86,8.86,0,0,1,.73.52l.38.29c.22.17.44.35.65.54s.6.54.88.82A17.47,17.47,0,0,1,42.54,20.5Z"/>
                <path className='as-lock-path' d="M31.23,4.14l-.14-.06A1.31,1.31,0,0,0,30.81,4l.56.21Zm6.19,4c-.28-.28-.57-.56-.88-.82s-.43-.37-.65-.54l-.38-.29A8.86,8.86,0,0,0,34.78,6c-.27-.18-.55-.36-.83-.51l-.16-.09a.8.8,0,0,1-.16-.08,17.68,17.68,0,0,1,3.75,2.85A17.47,17.47,0,0,1,42.5,20.5V34h0V20.5A17.47,17.47,0,0,0,37.42,8.13Z"/>
                <path className='as-lock-path' d="M45.89,34V20.89a20.89,20.89,0,1,0-41.77,0V34A4.54,4.54,0,0,0,0,38.55v25.9A4.55,4.55,0,0,0,4.55,69H45.46A4.54,4.54,0,0,0,50,64.45V38.55A4.54,4.54,0,0,0,45.89,34ZM27.5,52.33V63h-5V52.33a5,5,0,1,1,5,0ZM42.54,34H7.5V20.5A17.46,17.46,0,0,1,17.2,4.85l.69-.33.71-.3L19.33,4l.74-.24q.48-.13,1-.24a2.22,2.22,0,0,1,.37-.09c.32-.07.65-.13,1-.18A17.81,17.81,0,0,1,25,3a17.37,17.37,0,0,1,2.62.2c.47.07.94.16,1.4.27.23,0,.47.11.69.18A8.16,8.16,0,0,1,30.81,4a1.31,1.31,0,0,1,.28.09l.14.06.14.06a13.79,13.79,0,0,1,2.42,1.16l.16.09c.28.15.56.33.83.51a8.86,8.86,0,0,1,.73.52l.38.29c.22.17.44.35.65.54s.6.54.88.82A17.47,17.47,0,0,1,42.54,20.5Z"/>
                <path className='as-lock-path' d="M31.23,4.14l.14.06L30.81,4a1.31,1.31,0,0,1,.28.09Z"/>
                <path className='as-lock-path' d="M42.54,20.5V34h0V20.5A17.47,17.47,0,0,0,37.38,8.13a17.68,17.68,0,0,0-3.75-2.85.8.8,0,0,0,.16.08l.16.09c.28.15.56.33.83.51a8.86,8.86,0,0,1,.73.52l.38.29c.22.17.44.35.65.54s.6.54.88.82A17.47,17.47,0,0,1,42.54,20.5Z"/>
            </svg>
            <span className='as-authorization-message'>Sorry, you are not authorized to view this page.</span>
            <span className='as-authorization-info'>Please contact your administrator.</span>
            <div className='as-authorization-logo'>
            <span>AEGIS SOFTWARE PVT. LTD.</span>
            </div>
        </div>
    )
}

export default Unauthorized
