import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faArrowRight, faChevronRight, faAnchor, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { TitleCase } from 'library/Library';

export default function MegaMenu({ moduleName, menuList, thePOP, updateActiveModuleId, moduleId }) {

    const [search, setSearch] = useState("");

    const ref_search = useRef();

    const [filterString, setFilterString] = useState("");

    useEffect(() => {
        ref_search.current.focus();
    });

    const filterMenuList = (e) => {
        setFilterString(e.target.value);
    }



    if (true) {

        return (
            <>
                <div className="as-mega-menu" onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        thePOP()
                    } else {
                        e.preventDefault()
                    }
                }}>
                    <div className='as-mega-menu-content'>
                        <div className='as-mega-menu-header'>
                            <span className='as-mega-menu-module-name'>
                                {moduleName} MENU LIST
                            </span>
                            <input
                                type='search'
                                name='search'
                                placeholder='Search Menu'
                                className='as-search-menus'
                                ref={ref_search}
                                autoComplete='off'
                                onChange={(e) => filterMenuList(e)}
                            />
                        </div>
                        <div className='as-mega-menu-body'>
                            <div className='as-mega-menu-group'>
                                {
                                    menuList.map(y => {
                                        return (
                                            <div className='as-mega-menu-lists-div' key={y.id}>
                                                <ul>
                                                    <span>{TitleCase(y.name)}</span>
                                                    {
                                                        y.level_three.map(l => {

                                                            if (l.description.toLowerCase().includes(filterString.toLowerCase()))
                                                                return (
                                                                    <li className='as-mega-menu-lists' key={l.id}>
                                                                        <Link className='as-mega-menu-redirect-links' title={l.name} to={l.link}
                                                                            onClick={(e) => {
                                                                                var event = e ? e : window.event;
                                                                                if (event.ctrlKey) {
                                                                                    e.preventDefault();
                                                                                    var targetUrl = e.target.href;
                                                                                    if (e.target.tagName.toLowerCase() == "svg") {
                                                                                        targetUrl = e.target.parentElement.href;
                                                                                    } else if (e.target.tagName.toLowerCase() == "path") {
                                                                                        targetUrl = e.target.parentElement.parentElement.href;
                                                                                    }
                                                                                    window.open(e.target.href, '_blank');
                                                                                } else {
                                                                                    updateActiveModuleId(moduleId);
                                                                                    thePOP();
                                                                                }


                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon className='as-mega-menu-arrow as-space-right' style={{ fontSize: '12px !important' }} icon={faAngleRight} />
                                                                            {TitleCase(l.name)}
                                                                        </Link>
                                                                    </li>
                                                                )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

    } else {
        return (<></>)
    }

}