import React,{useEffect,useState} from 'react';
import {uuidv4} from 'library/Library.js';
// import './Loader.css';
import '../../styles/scss/loader.scss'

/**
 * 
 * @height = 100% or 100px, if you want to force the height it should take 
 * @left = 100% or 100px, if you want to force the left margin it should take 
 */
function Loader(props) {
    const [style, setStyle] = useState({});
    const [uniqueClass,setUniqueClass]=useState(" overlay_"+uuidv4().split('-').join("_"));

    return (
        <div className={"as-loader-overlay"+uniqueClass} id="overlay-of-refresh" style={style} >
            <div className="as-loader-overlay-inner">
                <div className="as-loader-overlay-content">
                    <div className="dot1"></div>
                    <div className="dot2"></div>
                    <div className="dot3"></div>
                </div>
            </div>
        </div>
    );
}

export default Loader;