import React,{useEffect,useState,useRef, forwardRef, useImperativeHandle} from 'react';
import ReactDOM from 'react-dom';
// import './Snackbar.css';
import 'styles/scss/toast.scss';
import { uuidv4 } from 'library/Library';
import useDeepEffect from 'hooks/useDeepEffect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
/**
 * It shows snackbar based on response from API. It does not show snack bar if message ='' 
 * @res Response from API.
 * @type Type of snackbar, if not provided it is computed based on error_code.
 */
export function ResponseSnackbar(res,type='',duration=5000){
    if(type==''){
        if(res.error_code==1) type='error';
        if(res.error_code==0) type='success';
        if(res.error_code==2) type='warning';
        if(res.error_code==3) type='info';
    }
    if(res.message!='' && res.message!=null)
        ShowSnackbar(type,res.message,duration);
}

export function ShowSnackbar(type,message,duration=5000){
    

    const element = (
        <SnackbarWrapper 
            type={type}
            message={message}
            duration={duration}    
        />
      );
      
      ReactDOM.render(element, document.getElementById('app-snackbar'));
}

export function HideSnackbar(){
    ReactDOM.render(<></>, document.getElementById('app-snackbar'));
}

function SnackbarWrapper(props){
    const [SnackbarList,setSnackbarList]=useState([]);

    const [batchUUID,setBatchUUID]=useState();

    useDeepEffect(()=>{
        if(SnackbarList.length>0){
            const timer = window.setInterval(() => {
                SnackbarList.forEach(x=>{
                    if(x.expireTime>new Date()){
                        removeSnackBar(x.uuid); 
                    }
                })

                return () => {
                    window.clearInterval(timer);
                  };
            }, props.duration);
            
        }
    },[SnackbarList])

    
    const removeSnackBar = (uuid) => {
        
        setSnackbarList(p=>([
            ...p.filter((x)=>{
                if(x.uuid==uuid){}
                else
                    return {
                        type:x.type
                        ,message:x.message
                        ,expireTime:x.expireTime
                        ,onExpire:removeSnackBar
                        ,uuid:uuidv4()
                    } 
            })
        ]))
        
    }

    useEffect(()=>{
        var expireTime = new Date();
        expireTime.setSeconds(expireTime.getSeconds() + parseInt(props.duration/100));


        setBatchUUID(uuidv4().split("-").join("_"))
        setSnackbarList(p=>([
            ...p,   {
                        type:props.type
                        ,message:props.message
                        ,expireTime:expireTime
                        ,onExpire:removeSnackBar
                        ,uuid:uuidv4()
                }
        ]));
    },[props]);

    const ref_snackbars=useRef([]);
    var totalHeight=0;
        return(
            <div>
                {SnackbarList.map((x,index)=>{
                    if(index==0) totalHeight=0;
                    else totalHeight=totalHeight+ref_snackbars.current[index-1].getHeight()+((index+1)*10);
                    
                    return <Snackbar 
                        ref={el => ref_snackbars.current[index] = el}
                        key={index}
                        type={x.type}
                        message={x.message}
                        expireTime={x.expireTime}
                        index={index}
                        onExpire={x.onExpire}
                        uuid={x.uuid}
                        height={totalHeight}
                    />
                })}
            </div>
        );
    
}

const Snackbar=forwardRef((props,ref)=>{

    const ref_self=useRef();
    
    const [show, setShow] = useState(true);
    const [isHovered, toggleHover] = useState(false);

    useImperativeHandle(ref,()=>({
        getHeight(){
            return parseInt(ref_self.current.offsetHeight);//Returning current height
        }
    }));

    const removeSnackBar=(uuid)=>{
        
        props.onExpire(uuid)
    }

    var snackBarClass="";
    if(props.type=="success"){
        snackBarClass="as-toast-success";
    }
    else if(props.type=="warning"){
        snackBarClass="as-toast-warning";
    }
    else if(props.type=="info"){
        snackBarClass="as-toast-info";
    }
    else if(props.type=="error"){
        snackBarClass="as-toast-error";
    }
    ;
    if(show){
        var totalHeight=props.height;
       
        return (<>
            <div 
                ref={ref_self}
                onMouseEnter={toggleHover}
                onMouseLeave={toggleHover}
                onClick={()=>{removeSnackBar(props.uuid)}} 
                className={"as-toast "+ snackBarClass+" as-toast-show "}
                style={{top:totalHeight+"px",cursor:"pointer"}}
            >
                {props.message}
                <button className='as-toast-remove' title='Close'>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
            </>
        );
    }
    else
        return(<></>);
        
});
