import React,{useContext, useEffect} from 'react';
import './Topbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell,faUser,faCog,faSignOutAlt,faCalendar,faEllipsisV, faQuestion } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'layout/dropdown/Dropdown';
import {LogoutUser} from 'modules/login/Login';
import { ShowModal,HideModal } from 'layout/modal/Modal';
import ChangePassword from './component/ChangePassword';
import { GetAcDate, ToDateString, DateFormat } from 'library/Library';
import Loader from 'layout/loader/Loader';
import {AppContext} from 'App.js';
import { ShowSnackbar } from 'layout/snackbar/Snackbar';
import { AlertMessage } from 'layout/alert/Alert';


function Topbar(props) {

    var ac_date=useContext(AppContext).appState.ac_date;
    if(ac_date==null){
        ac_date=(<Loader />);
    }else{
        ac_date=ToDateString(ac_date);
    }

    useEffect(()=>{
        if(ac_date==null || React.isValidElement(ac_date)) return;
        var format=DateFormat();

        if(format==null || format=="null"){
            ShowSnackbar('warning',"Please setup property information.");
            
            if(window.location.pathname!=="/app/setup/propertysetup"){
            AlertMessage({type:'warning',title:'Setup Incomplete!',message:'Please setup property information.',btnTypeId:1,confirmCallBack:()=>{
                window.location.href="/app/setup/propertysetup?t=2";
            },btnConfirmText:"Ok"});
            }
        }
    },[ac_date]);
    
    return (
        <>
            <div className="topmenubar">
                <ul className="topmenubar-item item-left" style={{marginLeft:0,paddingLeft:"15px"}}>
                    <li className="topmenubar-li brand-item">
                        <span className="company-text">AEGIS SOFTWARE</span>
                    </li>
                    <li className="topmenubar-li">
                        <input className="search-box" type="search" placeholder="Search..." />
                    </li>
                    <li className="topmenubar-li" style={{color:"dodgerblue"}}>
                        <span title="Accounting Date" style={{cursor:"default"}}>
                            <FontAwesomeIcon icon={faCalendar} className="space-right"  style={{width:"2em"}} /> 
                            {ac_date}
                        </span>
                    </li>
                </ul>
                <ul className="topmenubar-item float-right">
                    
                    
                    <li className="topmenubar-li small-screen-hide">
                    
                    
                        <Dropdown 
                            buttonName={<FontAwesomeIcon icon={faEllipsisV} />}
                            buttonClass="toogle-dropdown as-topmenubar-dropbtn"
                            buttonTitle="Options">
                                <a href={void(0)}><FontAwesomeIcon icon={faUser} className="space-right" style={{width:"2em"}}/>
                                {localStorage.first_name+" "+localStorage.middle_name+" "+localStorage.last_name}
                                </a>
                                
                                <a href={void(0)} 
                                    onClick={()=>{
                                        ShowModal({modalTypeId:"4",title:"Change Password",content:(
                                            <ChangePassword />
                                        )})
                                    }}
                                ><FontAwesomeIcon icon={faCog} className="space-right"  style={{width:"2em"}} />Change Password</a>
                                <a href={void(0)}
                                    onClick={()=>{
                                        ShowModal({modalTypeId:"3",title:"Help",content:(
                                        <>
                                        <div className="as-modal-body">
                                            <table className="as-table as-table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Keys</th>
                                                        <th>Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>ALT + V</td>
                                                        <td>View Page</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ALT + M</td>
                                                        <td>Manage Page</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ALT + S</td>
                                                        <td>Sync Drop Down</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ALT + L</td>
                                                        <td>Close Modal Pop Up</td>
                                                    </tr>
                                                    <tr>
                                                        <td>CTRL + S</td>
                                                        <td>Save</td>
                                                    </tr>
                                                    <tr>
                                                        <td>F2</td>
                                                        <td>Focus on home element</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="as-modal-footer">
                                            <button className="as-btn" onClick={HideModal}>
                                                Close
                                            </button>
                                        </div>
                                        </>
                                        )})
                                    }}
                                ><FontAwesomeIcon icon={faQuestion} className="space-right" style={{width:"2em"}}/>Help</a>
                                <div className="as-divider"></div>
                                <a href={void(0)} onClick={()=>{LogoutUser()}}><FontAwesomeIcon icon={faSignOutAlt} className="space-right"  style={{width:"2em"}} /> Logout</a>
                        </Dropdown>
                        
                    </li>
                    
                </ul>
            </div>
        </>
    );
}

export default Topbar;