import React,{useRef,useEffect} from 'react';
import {isEqual} from 'lodash';


export default function useDeepEffect(callback, dependencies) {
  useEffect(callback, useDeepCompareMemoize(dependencies))
}


function useDeepCompareMemoize(value) {
  const ref = useRef() 
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!isEqual(value, ref.current)) {
    ref.current = [...value]
  }

  return ref.current
}