import React,{useState, useRef, useEffect} from 'react';
import '../../styles/scss/actionbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {LogoutUser} from 'modules/login/Login';
import { faAngleLeft,faAngleRight,faHome,faSync, faGlassCheers, faTachometerAlt, faIdBadge, faBusinessTime, faCubes, faDollarSign, faHandHoldingUsd, faSearch, faUser, faCog, faChartPie, faComments, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";
import Dropdown from 'layout/dropdown/Dropdown';
import useModalStack, { ModalPopUp } from 'hooks/useModalStack';
import ChangePassword from 'layout/actionbar/component/ChangePassword';

function Actionbar(props) {
    const [reloadMenu, setReloadMenu] = useState(0);

    const [modal,push,pop]= useModalStack();

    const ref_change_password=useRef();

    

    return (
        <>
            {modal}
            <div className='as-actionbar'>
                <ul className="as-actions">
                    {/* <li className="as-actions-lists">
                        <FontAwesomeIcon icon={faSearch} />
                    </li> */}
                    <li className="as-actions-lists">
                        <Dropdown 
                            buttonName={<FontAwesomeIcon icon={faCog} />}
                            buttonClass="toogle-dropdown as-actions-dropdown-button"
                            buttonTitle="Options"
                        >
                            <a>Aegis Software</a>
                            <a href={void(0)} onClick={()=>{
                                push(<ModalPopUp 
                                    size="x-small"
                                    pop={pop}
                                    key={1}
                                    body={<ChangePassword pop={pop} ref={ref_change_password} />}
                                    header="Change Password"
                                    footer={<>
                                        <button onClick={()=>{
                                            ref_change_password.current.UpdatePassword();
                                        }} type="button" className="as-default-btn-solid">
                                            Update
                                        </button>
                                        
                                    </>}
                                />)
                            }}>Change Password</a>
                            <a>Help</a>
                            <div className='as-divider'></div>
                            <a href={void(0)} onClick={()=>{LogoutUser()}}><FontAwesomeIcon icon={faSignOutAlt} className="space-right"  style={{width:"2em"}} /> Logout</a>
                        </Dropdown>
                    </li>
                </ul>
            </div>
        </>
    );
}



export default Actionbar;