import React,{useEffect,useState, useContext} from 'react';
import {uuidv4} from 'library/Library.js';
import '../../styles/scss/loader.scss'
import ProgressBar from "@ramonak/react-progress-bar"
import CssVariables from 'styles/scss/variables.scss'
import { LoaderContext } from 'hooks/useLoader'
import { AegisIcon } from 'layout/svgs/SVG'

/**
 * 
 * @height = 100% or 100px, if you want to force the height it should take 
 * @left = 100% or 100px, if you want to force the left margin it should take 
 */
function Loader({message, isContinuous, ...props}) {
    const { progress } = useContext(LoaderContext)
    const [style, setStyle] = useState({});
    const [uniqueClass,setUniqueClass]=useState(" overlay_"+uuidv4().split('-').join("_"));

    useEffect(() => {
        var style=LoaderStyle(uniqueClass);
        if(props.height!=undefined)
            style.height=props.height;
        if(props.left!=undefined)
            style.left=props.left;
        setStyle(style);
    },[setUniqueClass]);
    return  (
        <div className={'as-progress-bar-loader-wrapper'+uniqueClass}>
            <div className='as-progress-bar-loader-content'>
                {
                    isContinuous
                    ?
                        <div className='continuous-loader-wrapper'>
                            <div className='continuous-loader'>
                                <AegisIcon />
                            </div>
                            <span className='loading-text'>{message}</span>
                        </div>
                    :
                        <>
                            <ProgressBar
                                animateOnRender={props.animate || false}
                                baseBgColor={CssVariables.defaultBorderColor}
                                bgColor={CssVariables.defaultThemeColor}
                                completed = {progress}
                                completedClassName='completed-progress-bar-loader'
                                height={6}
                                isLabelVisible={false}
                                maxCompleted = {100}
                                transitionDuration={'0.5s'}
                            />
                            <br/>
                            <span className='as-progress-bar-loader-value'>{progress}% completed</span>
                            <br/>
                            <br/>
                            <span className='as-progress-bar-loader-message'>{message}</span>
                        </>
                }
            </div>
        </div>
    );

}

function LoaderStyle(className){
    
    var element=document.getElementsByClassName(className)[0].parentElement;
    
    var positionInfo = element.getBoundingClientRect();
    var height = positionInfo.height;
    var width = positionInfo.width;
    var position = element.getBoundingClientRect();

    var style={
        height:height+"px",
        width:width+"px",
        top:element.offsetTop+"px",
        left:element.offsetLeft+"px"
    };
    return style;
}

export default Loader;